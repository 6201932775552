<template>
  <BittsContainer :drop-shadow="props.dropShadow" :no-padding="props.noPadding">
    <div v-if="showTitle" class="bitts-card__title">
      <slot name="title">
        <span>{{ props.titleText }}</span>
      </slot>
      <slot name="action" />
    </div>
    <BittsDivider v-if="showTitle" class="mt-0" />
    <slot />
  </BittsContainer>
</template>

<script setup lang="ts">
import { computed } from 'vue';

import BittsContainer from '../BittsContainer/BittsContainer.vue';
import BittsDivider from '../BittsDivider/BittsDivider.vue';

export type Props = {
  dropShadow?: boolean;
  noPadding?: boolean;
  titleText?: string;
};

const props = withDefaults(defineProps<Props>(), {
  dropShadow: false,
  noPadding: true,
  titleText: '',
});

const showTitle = computed(() => props.titleText.length > 0);
</script>

<style lang="pcss">
.bitts-card__title {
  @apply text-base flex flex-row items-center justify-between p-16;
}
</style>
